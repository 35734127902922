@import url("https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,500,600,700,800&display=swap");

@import "./custom-bootstrap.scss";
@import "~antd/dist/antd.css";
@import "@aws-amplify/ui/dist/style.css";
@import "./style.scss";

* {
  touch-action: manipulation;
}

html,
body {
  font-family: "Open Sans", sans-serif;
  touch-action: manipulation;
  width: 100%;
  height: 100%;
}

.cursor-pointer{
  cursor: pointer;
}

.card-tabs {
  .card-header {
    padding-bottom: 0;
  }
  .nav-tabs {
    margin: -1px;
  }
}
.with-border{
  border-color:#e2e2e2;
  border-width: 1px;
  border-style: solid;
}

.loader{
  position: absolute;
  z-index: 99;
  top:0;bottom: 0;left:0;right:0;
  background: rgba(#008d3c,0.5);
  display: block;
  align-items: center;
  justify-content: center;
  display: flex;
}

.border-right{
  border-right: 1px solid #e2e2e2;
}
.list-group-item{
  &.active{
    background-color: #ffb711;
  }
}
// for page template
button.ant-btn{
  margin-right: 8px;
  margin-bottom: 12px;
}
.ant-form-item-label>label {
  font-weight: 600;
}
.ant-form-item-control-input-content .ant-picker{
  width:100%;
}