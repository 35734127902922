.logo{
    img{
        // height: 32px;
        margin-right: 16px;
        position: relative;
        top: -1.5px;
        max-width: 60px;
        min-width: 50px;
    }
    &__name{
        font-size: 0.9rem;
        font-weight: 700;
        color: #ffffff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 90%;
        display: block;
        align-self: center;
    }
    
}
.header {
    background: #ffffff !important;
}