$body-bg: #F9F9F9;
$primary: #008d3c;
$secondary: #ffb711;

$dark: #1D252C;

$btn-border-radius: 0px;
$btn-font-weight: 100;
$input-border-radius:0px;
$dropdown-menu-border-radius:0px;
$drop-down-menu-border-radius:0px;
$modal-content-border-radius:0px;
$form-control-border-radius:0px;

$input-btn-padding-y:  0.525rem;
$input-btn-padding-x:  2rem;
$input-btn-font-size: 0.775rem;

$card-border-radius: 0px;
$card-border-color: transparent;

$input-font-size: 0.775rem;
$input-padding-y:  0.5rem;
$input-padding-x:  0.5rem;
$input-font-weight: 100;

@import "~bootstrap/scss/bootstrap";
