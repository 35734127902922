@import 'styles/mixins.scss';

.topbar {
  background: transparent;
  min-height: rem(64);
  height: rem(64);
  border-bottom: 1px solid $border;
  padding: rem(15) rem(20);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

[data-kit-theme='dark'] {
  .topbar {
    background: darken($dark-gray-5, 3%);
    color: $dark-gray-1;
    border-bottom: 1px solid $dark-gray-4;
  }
}
